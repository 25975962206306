import React from "react";
import Company from "../components/Pages/Products";
import Testimonial from "../components/Pages/Testimonial";
import Services from "../components/Pages/Services";
import Introduction from "../components/Pages/Introduction";
import BannerTop from "../components/Pages/BannerTop";
import Home from "../components/Pages";

function Landing() {
  return (
    <div>
      <BannerTop />
      <Introduction />
      <Services />
      <Company />
      <Testimonial />
    </div>
  );
}

export default Landing;
