import React, { useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import { Container, makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { animateScroll as scroll } from "react-scroll";
import { Link } from "react-scroll";
import { useSpring, animated } from "react-spring";
import "./Button.css";
import {
  createMuiTheme,
  withStyles,
  ThemeProvider,
} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    align: "center",
    borderRadius: 15,
  },
  head: {
    color: "#FF883B",
    fontFamily: "Rubik",
    fontWeight: "bold",
    fontSize: "1.8vw",
    marginBottom: "2.5vw",
    marginTop: "3vw",
    [theme.breakpoints.down("sm")]: {
      fontSize: "3.5vw",
    },
  },
}));
const ColorButton = withStyles((theme) => ({
  root: {
    align: "center",
    borderRadius: "4px",
    padding: "10px 22px",
    outline: "none",
    border: "none",
    color: "white",
    boder: "4px solid #D71E3D",
    backgroundColor: "black",
    transition: "0.8s",
    background: "linear-gradient(to right, #000, #D71E3D )",

    backgroundSize: "400px 80px",
    "&:hover": {
      backgroundColor: "#D71E3D",
      backgroundPosition: "200px 0px",
    },
  },
}))(Button);

function BannerTop() {
  const n = useRef(0);
  const styles = useSpring({
    from: { y: -40 },
    config: { duration: 1000 },
    loop: {
      y: 0,
    },
  });
  const [style, animate] = useSpring(() => ({ x: 0 }));

  // Pass this to an element.
  const onClick = () => {
    animate({ x: 1, delay: 0 });

    // Prevent the previous update. 🤣
    animate({ cancel: true });
  };
  const classes = useStyles();
  const toggleFooter = () => {
    scroll.scrollToTop();
  };

  return (
    <div
      style={{
        height: "1000px",
        width: "100%",
        backgroundImage: `url(/side.png)`,
        textAlign: "center",
      }}
      className={classes.head}
    >
      <Container backgroundColor="white">
        <animated.div style={styles}>
          <Typography
            style={{ color: "#1E266D", marginTop: "10%", fontType: "Roboto" }}
            variant="h4"
            align="center"
            gutterBottom
          >
            {" "}
            Dreams Made Into Reality
          </Typography>
          <Typography
            style={{ color: "#236c7e", Fonttype: "Roboto" }}
            variant="h6"
            align="center"
            color="textSecondary"
            paragraph
          >
            Our Team 24Streets is ready to provide you with high quality
            software services and Products
          </Typography>
          <Link
            to="services"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            <ColorButton>Explore Us</ColorButton>
          </Link>
        </animated.div>
      </Container>
    </div>
  );
}

export default BannerTop;
