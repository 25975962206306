import "./App.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Services from "./components/Pages/Services";
import Sidebar from "./components/Sidebar";
import Home from "./components/Pages/index";
import Company from "./components/Pages/Products";
import Testimonial from "./components/Pages/Testimonial";
import Footer from "./components/Footer/Footer";
import Introduction from "./components/Pages/Introduction";
import Mobile from "./Screens/MobileDevelopment";
import Web from "./Screens/WebDevelopment";
import Landing from "./Screens/Landing";
import Navbar from "./components/Navbar";
import ScrollToTop from "./components/Pages/ScrollToTop";
import Ui from "./Screens/UIDesigning";
import Enterprise from "./Screens/Enterprise";
import AI from "./Screens/AI";
import IOT from "./Screens/IOT";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Sidebar />
      {/*<Home/>*/}
      {/*<Navbar/>*/}
      <Home />
      <Switch>
        <Route path="/artificial" component={AI} />
        <Route path="/iot" component={IOT} />
        <Route path="/enterprise" component={Enterprise} />
        <Route path="/uiuxdesign" component={Ui} />
        <Route path="/web" component={Web} />
        <Route path="/mobile" component={Mobile} />
        <Route path="/" exact component={Landing} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
