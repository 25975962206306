import {colors, Typography} from "@material-ui/core";
import React from "react";
import Carousel from "react-elastic-carousel";
import "./testimonial.css";
import image from "./images/images.jpeg";
import dinu from "./images/dinu.png";
import anjana from "./images/anjana.png";
import ishara from "./images/ishara.png";
import {makeStyles} from "@material-ui/core/styles";
import AOS from 'aos';
import "aos/dist/aos.css";

const useStyles = makeStyles((theme) => ({
    ul: {
        alignContent: "center",
        align: "center",
    },
    card_box: {
        padding: "4%",
        border: "solid",
        borderColor: "#EBECF0",
        borderWidth: 1,
        minHeight: 400,
        borderRadius: 10,
        justifyContent: "center",
    },
    title: {
        color: "#1E266D",
        fontFamily: "Roboto",
        fontWeight: 450,
        fontSize: "40px",
        marginTop: "3%",
        [theme.breakpoints.down("sm")]: {
            fontSize: "26px",
        },
    },
    head: {
        // color: "#FF883B",
        fontFamily: "Rubik",
        fontWeight: "bold",
        fontSize: "1.8vw",
        marginBottom: "2.5vw",
        marginTop: "3vw",
        [theme.breakpoints.down("sm")]: {
            fontSize: "3.5vw",
        },
    },
}));

const breakPoints = [
    {width: 1, itemsToShow: 1},
    {width: 550, itemsToShow: 2},
    {width: 1200, itemsToShow: 3},
];
const items = [
    {id: 1, title: "item #1"},
    {id: 2, title: "item #2"},
    {id: 3, title: "item #3"},
    {id: 4, title: "item #4"},
    {id: 5, title: "item #5"},
];

export default function Testimonial() {
    const classes = useStyles();
    AOS.init();

    return (
        <>
            <div id={"company"} style={{margin: "1%", marginTop: "5%"}}>
                <Typography
                    className={classes.title}
                    variant="h3"
                    align="center"
                    gutterBottom
                >
                    What others are saying
                </Typography>
                <Typography align="center">
                    Here are few of our clients words regarding our work
                </Typography>
                <div className="App" style={{marginTop: "3%"}} data-aos="fade-zoom-in" data-aos-duration="3000">
                    <Carousel
                        breakPoints={breakPoints}
                        itemPadding={[20, 40]}
                        itemsToShow={3}
                    >
                        <div className={classes.card_box}>
                            <p
                                style={{marginTop: "5%", color: "#1E266D", textAlign: "left"}}
                            >
                                "We have been working with 24Streets team for a long time and have embarked on many
                                projects with them. One of the projects were a sales force automation application
                                (Android), and integrating it to a backend ERP web based application. They did an
                                excellent job, and i was highly satisfied with the service that they provided"
                            </p>
                            <img
                                style={{marginTop: "5%"}}
                                src={anjana}
                                widith="75"
                                height="75"
                            ></img>
                            <p style={{marginTop: "4%", fontWeight: "bold"}}>Anjana Seneviratne</p>
                            <p style={{marginTop: "-4%", color: "#1E266D"}}> Director, Prestige Asia Technologies
                            </p>
                        </div>

                        <div className={classes.card_box}>
                            <p
                                style={{marginTop: "5%", color: "#1E266D", textAlign: "left"}}
                            >
                                "24Streets is a good company to work with and their process and the quality of deliverables are outstanding.
                                The team is flexible and they are always ahead with the latest technologies. Good luck guys for your future work!"
                            </p>
                            <img
                                style={{marginTop: "16%"}}
                                src={ishara}
                                widith="75"
                                height="75"
                            ></img>
                            <p style={{marginTop: "4%", fontWeight: "bold"}}>Ishara Gunaratne</p>
                            <p style={{marginTop: "-4%", color: "#1E266D"}}>CTO, Oklo Pvt Ltd</p>
                        </div>




                        <div className={classes.card_box}>
                            <p
                                style={{marginTop: "5%", color: "#1E266D", textAlign: "left"}}
                            >"24 Streets is a very agile Startup for your Tech Projects. I've never encountered any issues working with them.
                                Every time they were able to deliver the projects within the given time frame despite sudden requirement changes.
                                24 Streets is my go-to place for tech projects. "
                            </p>
                            <img
                                style={{marginTop: "10%"}}
                                src={dinu}
                                widith="75"
                                height="75"
                            ></img>
                            <p style={{marginTop: "4%", fontWeight: "bold"}}>Dinushanka Ramawikrama</p>
                            <p style={{marginTop: "-4%", color: "#1E266D"}}>Senior Product Engineer</p>
                        </div>
                        <div className={classes.card_box}>
                            <p
                                style={{marginTop: "5%", color: "#1E266D", textAlign: "left"}}
                            >
                                The standard chunk of Lorem Ipsum used since the 1500s is
                                reproduced below for those interested. The standard chunk of
                                Lorem Ipsum used since the 1500s is reproduced below for those
                                interested.
                            </p>
                            <img
                                style={{marginTop: "8%"}}
                                src={image}
                                widith="75"
                                height="75"
                            ></img>
                            <p style={{fontWeight: "bold"}}>Mr Alex Hales</p>
                            <p style={{marginTop: "-2%", color: "#1E266D"}}>IT Manager</p>
                        </div>
                    </Carousel>
                </div>
            </div>
        </>
    );
}
