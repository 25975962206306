import React, { useRef,useState ,useEffect } from "react";
import Button from "@material-ui/core/Button";
import logo1 from "./images/logo1.jpeg";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { useSpring, animated, Transition } from "react-spring";
import { animateScroll as scroll } from "react-scroll";
import {
  createMuiTheme,
  withStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import AOS from 'aos';




const useStyles = makeStyles((theme) => ({
  button: {
    align: "center",
    borderRadius: 15,
  },
  head: {
    // color: "#FF883B",
    // fontFamily: "Rubik",
    fontWeight: "bold",
    fontSize: "30px",
    // marginBottom: "2.5vw",
    // marginTop: "3vw",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",

      [theme.breakpoints.down("md")]: {
        fontSize: "30px",
      },
    },

    subtitle: {
      marginBottom: "10%",
      // [theme.breakpoints.down('sm')]: {
      //     fontSize: "4.5vw",
      // },
      // [theme.breakpoints.down('md')]: {
      //     fontSize: "60px",
      // },
    },
  },
}));
const ColorButton = withStyles((theme) => ({
  root: {
    align: "center",
    borderRadius: "4px",
    padding: "10px 22px",
    outline: "none",
    border: "none",
    color: "white",
    boder: "4px solid #D71E3D",
    backgroundColor: "black",
    transition: "0.8s",
    background: "linear-gradient(to right, #000, #D71E3D )",

    //   background:"linear-gardient(0deg,transparent 50%,#D71E3D)",
    backgroundSize: "400px 80px",
    "&:hover": {
      backgroundColor: "#D71E3D",
      backgroundPosition: "200px 0px",
      // transform: 'scale(1)',
      //    transform: "translate3d(0,-40px,0)", opacity: 0
    },
    //  '&:focus': {
    //     boxShadow: 'white',
    //     backgroundColor: 'red',
    //     borderColor: 'red',
    //   },
    "&:active": {},
  },
}))(Button);
 
function Info() {
  // Pass this to an element.
  // const [showButton, setShowButton] = useState(true);
  // const [showMessage, setShowMessage] = useState(false);
  useEffect(() => {
    AOS.init({
      duration : 2000
    });
  }, [])

  const classes = useStyles();
  // useEffect(() => {
    // Update the document title using the browser API
  //  console.log ("info check")
  //  setShowMessage(true)
  // });
  return (
    <>
    <div style={{ margin: "7%", marginTop: "-12%" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <div style={{ textAlign: "center" }} data-aos="fade-right" data-aos-delay="10000">
              <img src={logo1} height="100%" width="50%"></img>
            </div>
          </Grid>
          <Grid item xs={12} sm={8} data-aos="fade-left" data-aos-delay="10000">
            <Typography
              style={{ color: "#1E266D", fontWeight: "bold" }}
              variant="h5"
              className={classes.head}
            >
              Our Introduction
            </Typography>
            <p className={classes.subtitle}>
              <br />
              24 Streets a company founded in the year of 2018 a young and fresh startup company , which provides cutting edge software to companies and clients. 24streets provides customers with tailor made software according to customer requirements, plus software products for their needs. Our company consist of young energetic team who can take the full responsibility of making high quality software solutions for our customers
            </p>
            <ColorButton>Contact Us</ColorButton>
          </Grid>
        </Grid>
      </div>
     
    </>
  );
}

export default Info;
