import React from "react";
import { Card, Typography } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import Grid from "@material-ui/core/Grid";
import {
  FooterContainer,
  FooterWrap,
  FooterLinkWrapper,
  FooterLinkItems,
  FooterLinkContainer,
  FooterLinkTitle,
  FooterLink,
  SocialIconLink,
  SocialIcons,
  SocialLogo,
  SocialMedia,
  SocialMediaWrap,
  WebsiteRights,
  ArrowIcon,
} from "./FooterElements";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { animateScroll as scroll } from "react-scroll";
import "./styles.css";

const useStyles = makeStyles((theme) => ({
  button: {
    align: "center",
    borderRadius: 15,
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

function Footer() {
  const classes = useStyles();
  const toggleHome = () => {
    scroll.scrollToTop();
  };
  return (
    <>
      <div style={{ marginTop: "5%" }} id="contactus">
        <Paper
          style={{
            backgroundColor: " #272D4E",
            padding: "3%",
            paddingBottom: "5%",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={8}>
              <p
                style={{
                  fontSize: 34,
                  color: "white",
                  margin: "8%",
                  marginTop: "6%",
                  textAlign: "center",
                }}
              >
                Ready to launch your next website?
              </p>
            </Grid>
            <Grid item xs={12} sm={4}>
              <div
                style={{
                  marginTop: "8%",
                  textAlign: "center",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  style={{ backgroundColor: " white", textAlign: "center" }}
                  variant="contained"
                  color="#272D4E"
                  className={classes.button}
                  align="center"
                >
                  <span style={{ color: "#46D68C" }}>
                    <EmailIcon />
                  </span>
                  &nbsp; Email Us
                </Button>
                <Typography style={{ backgroundColor: " #272D4E" }}>
                  <span style={{ color: "white", marginTop: "2%" }}>
                    <br />
                    via
                    <span style={{ color: "yellow" }}> services@24streets.com</span>
                  </span>
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </div>
      <div
        style={{ backgroundColor: "white", width: "100%", paddingTop: "3%" }}
      >
        <FooterLinkContainer>
          <FooterLinkWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>
                24Streets<span style={{ color: "#D71E3D" }}>:</span>
              </FooterLinkTitle>

              <FooterLink to="/">
                <span align="left">
                  Software Company that provides various kind of software services.

                </span>
              </FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Contact Us</FooterLinkTitle>
              <FooterLink to="/">services@24streets.com</FooterLink>
                <FooterLink to="/">+94 775822197</FooterLink>
                <FooterLink to="/">+94 770373556</FooterLink>
            </FooterLinkItems>
          </FooterLinkWrapper>
          <FooterLinkWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Address

</FooterLinkTitle>
              <FooterLink to="/">No 918, </FooterLink>
                <FooterLink to="/">Electricity board Rd, </FooterLink>
              <FooterLink to="/">Battaramulla,</FooterLink>
                <FooterLink to="/">Sri Lanka</FooterLink>

            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Connect With Us</FooterLinkTitle>
              <SocialIcons>
                <SocialIconLink
                  href="https://www.facebook.com/24streets/"
                  target="_blank"
                  arial-label="Facebook"
                  style={{ size: "1px" }}
                >
                  <FaFacebook />
                </SocialIconLink>
                <SocialIconLink
                  href="https://www.instagram.com/24streets/"
                  target="_blank"
                  style={{ marginLeft: "15px" }}
                  arial-label="Instagram"
                >
                  <FaInstagram />
                </SocialIconLink>
                {/*<SocialIconLink*/}
                  {/*href="//www.youtube.com/channel/UCF6Cz50AqAJcg6JC5LDRElg/videos?view_as=subscriber"*/}
                  {/*style={{ marginLeft: "15px" }}*/}
                  {/*target="_blank"*/}
                  {/*arial-label="Youtube"*/}
                {/*>*/}
                  {/*<FaYoutube />*/}
                {/*</SocialIconLink>*/}
                <SocialIconLink
                  href="/"
                  target="_blank"
                  arial-label="Twitter"
                  style={{ marginLeft: "10px" }}
                >
                  <FaTwitter />
                </SocialIconLink>
                <SocialIconLink
                  href="//www.linkedin.com/in/leonardtlauenstein/"
                  target="_blank"
                  style={{ marginLeft: "15px" }}
                  arial-label="Linkedin"
                >
                  <FaLinkedin />
                </SocialIconLink>
              </SocialIcons>
              <FooterLink to="/"></FooterLink>
              <FooterLink to="/"></FooterLink>
            </FooterLinkItems>
          </FooterLinkWrapper>
        </FooterLinkContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <WebsiteRights style={{ marginLeft: 0 }}>
              copyright © {new Date().getFullYear()} 24Streets All rights
              reserved.
            </WebsiteRights>
            <WebsiteRights style={{ marginLeft: 0 }}>
              <span>Privacy Policy</span>
              <span style={{ marginLeft: 10 }}>Terms Of Use</span>
              <span style={{ marginLeft: 20 }}>GDPR</span>
            </WebsiteRights>
            {/*<WebsiteRights></WebsiteRights>*/}
            {/*<WebsiteRights></WebsiteRights>*/}
            <div className="scroll-to-top">
              <ArrowIcon onClick={toggleHome}>
                <ArrowUpwardIcon className="icon" />
              </ArrowIcon>
            </div>
          </SocialMediaWrap>
        </SocialMedia>
      </div>
    </>
  );
}

export default Footer;
