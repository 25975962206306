import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import Button from "@material-ui/core/Button";
import pos from "./images/POS.png";
import fit from "./images/fit.png";
import bpm from "./images/bpm.png";
import { useState } from "react";
import {
  createMuiTheme,
  withStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import AOS from 'aos';
import "aos/dist/aos.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    margin: "2%",
  },
  icon: {
    color: "#F3B10E",
    fontSize: "20px",
  },
  title: {
    color: "#1E266D",
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "40px",
    marginTop: "3%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "26px",
    },
  },
  subtitle: {
    color: "#FF883B",
    fontSize: "20px",
    marginBottom: "2.5vw",
    marginTop: "3vw",
    [theme.breakpoints.down("sm")]: {
      fontSize: "3.5vw",
    },
  },
}));
const ColorButton = withStyles((theme) => ({
  root: {
    align: "center",
    borderRadius: "4px",
    padding: "10px 22px",
    outline: "none",
    border: "none",
    color: "white",
    boder: "4px solid #46D68C",
    backgroundColor: "#46D68C",
    transition: "0.8s",
    background: "linear-gradient(to right, #46D68C, #228B22 )",

    //   background:"linear-gardient(0deg,transparent 50%,#D71E3D)",
    backgroundSize: "400px 80px",
    "&:hover": {
      backgroundColor: "#46D68C",
      backgroundPosition: "200px 0px",
      // transform: 'scale(1)',
      //    transform: "translate3d(0,-40px,0)", opacity: 0
    },
    //  '&:focus': {
    //     boxShadow: 'white',
    //     backgroundColor: 'red',
    //     borderColor: 'red',
    //   },
    "&:active": {},
  },
}))(Button);

export default function Company() {
  const classes = useStyles();
  const [alignment, setAlignment] = useState("left");

  AOS.init();



  return (
    <div style={{ margin: "7%", marginTop: "10%" }} id="products">
      <div style={{ marginBottom: "3%" }}>
        <Typography
          className={classes.title}
          variant="h4"
          align="center"
          gutterBottom
        >
          Our Products help organizations move faster,
          <span>
            <br />
            Reduces costs and scalable
          </span>
        </Typography>
        <Typography align="center" color="textSecondary">
          We Provide You With The Latest Set Of Tools To Manage Your Bussiness & Body
        </Typography>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} sm={6} data-aos="fade-zoom-in" data-aos-duration="3000">
          <Paper className={classes.paper}>
            <Typography
              style={{
                color: "black",
                fontType: "DM Serif Display",
                fontWeight: "bold",
                marginTop: "10%",
                marginLeft: "15px",
                fontFamily: "Titillium Web",
                fontSize: 30,
              }}
              align={alignment}
            >
              Cloud POS
            </Typography>
            <Typography
              style={{
                color: "#F3B10E",
                fontType: "",
                fontWeight: "bold",
                marginLeft: "15px",
                fontSize: 20,
              }}
              align={alignment}
            >
              Management
            </Typography>
            <Typography align={alignment} style={{ marginLeft: "15px" }}>
              Our POS dynamic capabilities allow users in many different industries to use, easy customizations plus easy access for clients.
            </Typography>
            <Typography style={{ marginTop: "10%" }}></Typography>
            <hr></hr>
            <Typography style={{ marginTop: "10%" }}></Typography>
            <Typography
              align="left"
              style={{ marginLeft: "20px", fontSize: 14, fontWeight:'bold'  }}
            >
              <DoneIcon className={classes.icon}></DoneIcon>
              Manages Inventory
            </Typography>
            <Typography
              align="left"
              style={{ marginLeft: "20px", fontSize: 14, fontWeight:'bold'  }}
            >
              <DoneIcon className={classes.icon}></DoneIcon>
              Manages Stock
            </Typography>
            <Typography
              align="left"
              style={{ marginLeft: "20px", fontSize: 14, fontWeight:'bold'  }}
            >
              <DoneIcon className={classes.icon}></DoneIcon>
            Provides Sales Analytics
            </Typography>
            <Typography
              align="left"
              style={{ marginLeft: "20px", fontSize: 14, fontWeight:'bold'  }}
            >
              <DoneIcon className={classes.icon}></DoneIcon>
              Multiple Users & Terminals
            </Typography>

            {/*<ColorButton*/}
              {/*style={{*/}
                {/*backgroundColor: "  #46D68C",*/}
                {/*marginTop: "20%",*/}
                {/*marginLeft: "-25px",*/}
              {/*}}*/}
              {/*variant="contained"*/}
              {/*color="secondary"*/}
              {/*className={classes.button}*/}
            {/*>*/}
              {/*{" "}*/}
             {/*Visit Site*/}
            {/*</ColorButton>*/}
            <Typography style={{ margin: "12%"}}>
              <img src={pos} widith="1500" height="120"></img>
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} sm={6} data-aos="fade-zoom-in" data-aos-duration="3000">
          <Paper className={classes.paper}>
            <Typography
              style={{
                color: "black",
                fontType: "Roboto",
                fontWeight: "bold",
                textalign: "left",
                marginTop: "10%",
                marginLeft: "15px",
                fontFamily: "Titillium Web",
                fontSize: 30,
              }}
              align={alignment}
            >
              Fitness
            </Typography>
            <Typography
              style={{
                color: " #1DABF2",
                fontType: "",
                fontWeight: "bold",
                marginLeft: "15px",
                fontSize: 20,
              }}
              align={alignment}
            >
              Management
            </Typography>
            <Typography align={alignment} style={{ marginLeft: "15px" }}>
             We care about your fitness, our managment solutions provide solutions for fitness lovers , instructors & gyms to manage them.
            </Typography>
            <Typography style={{ marginTop: "10%" }}></Typography>
            <hr></hr>
            <Typography style={{ marginTop: "10%" }}></Typography>
            <Typography
              align="left"
              style={{ marginLeft: "20px", fontSize: 14, fontWeight:'bold'  }}
            >
              <DoneIcon className={classes.icon}></DoneIcon>
              Manages Excersice Shedules
            </Typography>
            <Typography
              align="left"
              style={{ marginLeft: "20px", fontSize: 14, fontWeight:'bold'  }}
            >
              <DoneIcon className={classes.icon}></DoneIcon>
             Manages Diet Plans
            </Typography>
            <Typography
              align="left"
              style={{ marginLeft: "20px", fontSize: 14, fontWeight:'bold'  }}
            >
              <DoneIcon className={classes.icon}></DoneIcon>
              Manages Users For Trainers
            </Typography>
            <Typography
              align="left"
              style={{ marginLeft: "20px", fontSize: 14, fontWeight:'bold' }}
            >
              <DoneIcon className={classes.icon}></DoneIcon>
              Manages Gyms
            </Typography>
            {/*<ColorButton*/}
              {/*style={{*/}
                {/*backgroundColor: "  #46D68C",*/}
                {/*marginTop: "20%",*/}
                {/*marginLeft: "-25px",*/}
              {/*}}*/}
              {/*variant="contained"*/}
              {/*color="secondary"*/}
              {/*className={classes.button}*/}
            {/*>*/}
              {/*{" "}*/}
              {/*Visit Site*/}
            {/*</ColorButton>*/}
            <Typography style={{ margin: "12%"}}>
              <img src={fit} widith="1500" height="120"></img>
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} sm={6} data-aos="fade-zoom-in" data-aos-duration="3000">
          <Paper className={classes.paper}>
            <Typography
              style={{
                color: "black",
                fontType: "Roboto",
                fontWeight: "bold",
                marginTop: "10%",
                marginLeft: "15px",
                fontFamily: "Titillium Web",
                fontSize: 30,
              }}
              align={alignment}
            >
              Bussiness Process
            </Typography>
            <Typography
              style={{
                color: " #46D68C",
                fontType: "",
                fontWeight: "bold",
                marginLeft: "15px",
                fontSize: 20,
              }}
              align={alignment}
            >
              Management
            </Typography>
            <Typography align={alignment} style={{ marginLeft: "15px" }}>
             24 BPM tool allows orgainzations to manage and plan bussiness process. Also track all bussiness process and provides analytics.
            </Typography>
            <Typography style={{ marginTop: "10%" }}></Typography>
            <hr></hr>
            <Typography style={{ marginTop: "10%" }}></Typography>
            <Typography
              align="left"
              style={{ marginLeft: "20px", fontSize: 14, fontWeight:'bold'  }}
            >
              <DoneIcon className={classes.icon}></DoneIcon>
              Manages Bussiness Flows
            </Typography>
            <Typography
              align="left"
              style={{ marginLeft: "20px", fontSize: 14, fontWeight:'bold'  }}
            >
              <DoneIcon className={classes.icon}></DoneIcon>
             Indicates Issues In Flows
            </Typography>
            <Typography
              align="left"
              style={{ marginLeft: "20px", fontSize: 14, fontWeight:'bold'  }}
            >
              <DoneIcon className={classes.icon}></DoneIcon>
              Provides Analytics
            </Typography>
            <Typography
              align="left"
              style={{ marginLeft: "20px", fontSize: 14, fontWeight:'bold'  }}
            >
              <DoneIcon className={classes.icon}></DoneIcon>
             Improvment Suggestions
            </Typography>
            {/*<ColorButton*/}
              {/*style={{*/}
                {/*backgroundColor: "  #46D68C",*/}
                {/*marginTop: "20%",*/}
                {/*fontType: "Roboto",*/}
                {/*marginLeft: "-25px",*/}
              {/*}}*/}
              {/*variant="contained"*/}
              {/*color="secondary"*/}
              {/*className={classes.button}*/}
            {/*>*/}
              {/*{" "}*/}
              {/*Visit Site*/}
            {/*</ColorButton>*/}
            <Typography style={{ margin: "12%"}}>
              <img src={bpm} widith="1500" height="120"></img>
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
