import React, {useState} from 'react';
import Navbar from '../Navbar/index';
import Sidebar from '../Sidebar/index';
import BannerTop from './BannerTop';

const Home = () => {
    //   const [isOpen, setIsOpen] = useState(false)

    // const toggle = () =>{
    //   setIsOpen(!isOpen);
    // }
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        //  console.log ("check",isOpen)
        setIsOpen(!isOpen);
    }

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Navbar toggle={toggle}/>
        </>
    );
};

export default Home;
