import { Link as LinkR } from "react-router-dom";
import styled from "styled-components";

export const NavBtn = styled.nav`
  display: flex;
  align-items: 'left';
  

  }
`;

export const NavBtnLink = styled(LinkR)`
  align: "left";
  background: ;
  //   padding: 10px 22px;
  color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  /* Second Nav */
  margin-left: 24px;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
    text-decoration: none;
    outline: none;
  border: none;
  }
`;
